// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classnames from 'classnames'

import map from 'lodash/map'
import filter from 'lodash/filter'
import isInteger from 'lodash/isInteger'
import isNull from 'lodash/isNull'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Iframe from 'react-iframe'

import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const pageSchema = {
  title: 'Audio Archive',
  slug: '/audio-archive',
  abstract: 'Audio archive',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Audio Archive', slug: '/audio-archive' },
  ],
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          title {
            lang
            content
          }
          dateThisTalkWasGivenByTheMother
          position
          routeSlug
          buzzsproutTag
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    data: {
      allResources: { edges },
    },
    pageContext: {
      intl: { language },
    },
    location: { hash },
  } = props

  const nodes = map(edges, 'node').slice(0, -1)
  const intlNodes = map(nodes, (node) => ({
    ...node,
    intlTitle:
      isNull(node.dateThisTalkWasGivenByTheMother) === false
        ? `${
            filter(node.title, ['lang', language])[0].content
          }, Mother's class of ${node.dateThisTalkWasGivenByTheMother}`
        : filter(node.title, ['lang', language])[0].content,
  }))

  return (
    <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
      <h1>Audio Archive</h1>
      <p className="dropcap">
        <span>O</span>n this page you will find the complete audio archive for{' '}
        <em>Mother's Question and Answers: The Auroville Radio Talks</em>. Click
        on an item in the contents below to see audio for that section.
      </p>
      {map(intlNodes, (node) => {
        const { intlTitle, position, buzzsproutTag } = node

        let returnThis = <Fragment />

        if (isNull(buzzsproutTag) === false) {
          if (buzzsproutTag !== 'NA') {
            let title = <Fragment>{intlTitle}</Fragment>

            if (isInteger(position) === false) {
              title = (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 8,
                  }}
                >
                  <div
                    style={{
                      height: '1px',
                      backgroundColor: 'var(--border-color)',
                      width: 20,
                    }}
                  />
                  <span style={{ paddingLeft: 8, flex: 1 }}>{intlTitle}</span>
                </div>
              )
            }

            returnThis = (
              <div
                className={classnames('audio-archive', 'flow', {
                  child: isInteger(position) === false,
                })}
              >
                <Link to={`/audio-archive#${position}`}>{title}</Link>
                {hash === `#${position}` && (
                  <div
                    style={{
                      marginTop: '0.225rem',
                      marginBottom: '0.225rem',
                      paddingLeft: isInteger(position) === false ? 36 : '0rem',
                    }}
                  >
                    <Iframe
                      url={`https://www.buzzsprout.com/1897213?artist=&client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1897213%2Fpodcast%2Fembed&tags=${buzzsproutTag
                        .split(' ')
                        .join('+')}`}
                      width="100%"
                      height="368px"
                      display="initial"
                      position="relative"
                    />
                  </div>
                )}
              </div>
            )
          }
        }

        return returnThis
      })}
      <h2>Podcast</h2>
      <p className="dropcap">
        <em>
          <span>M</span>other's Question and Answers: The Auroville Radio Talks
        </em>{' '}
        is also available on the <em>PRISMA Audio Books podcast</em> on these
        following networks:
      </p>
      <ul>
        <li>
          <Link to="https://podcasts.apple.com/us/podcast/prisma-audio-books/id1601147896">
            Apple Podcast
          </Link>
        </li>
        <li>
          <Link to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xODk3MjEzLnJzcw">
            Google Podcast
          </Link>
        </li>
        <li>
          <Link to="https://open.spotify.com/show/4Ne6IUUVuJjBbgmBGUbckw">
            Spotify
          </Link>
        </li>
        <li>
          <Link to="https://music.amazon.com/podcasts/a9bafab9-76f7-4ac8-9bc5-32604400ed3d/prisma-audio-books">
            Amazon Music
          </Link>
        </li>
        <li>
          <Link to="https://www.stitcher.com/show/prisma-audio-books">
            Stitcher
          </Link>
        </li>
        <li>
          <Link to="https://podcastaddict.com/podcast/3734046">
            PodcastAddict
          </Link>
        </li>
        <li>
          <Link to="https://www.podchaser.com/podcasts/prisma-audio-books-2988510">
            Podchaser
          </Link>
        </li>
        <li>
          <Link to="https://pca.st/crm11xg6">Pocket Casts</Link>
        </li>
        <li>
          <Link to="https://www.deezer.com/show/3248232">Deezer</Link>
        </li>
        <li>
          <Link to="https://www.listennotes.com/podcasts/prisma-audio-books-prisma-design-Y2QxG5OAZKg/">
            Listen Notes
          </Link>
        </li>
        <li>
          <Link to="https://player.fm/series/prisma-audio-books">
            Player FM
          </Link>
        </li>
        <li>
          <Link to="https://podcastindex.org/podcast/4534555">
            Podcast Index
          </Link>
        </li>
      </ul>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
